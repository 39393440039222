import { combineReducers } from 'redux'
import theme from './slices/themeSlice'
import auth from './slices/authSlice'
import plant from './slices/plantSlice'
import section from './slices/sectionSlice'
import alarm  from './slices/alarmSlice'
import task  from './slices/taskSlice'
import log from './slices/logSlice'
import meter from './slices/meterSlice'
import syncmate from './slices/syncmateSlice'
import dashboard from './slices/dashboardSlice'
import graph from './slices/graphSlice'
import setting  from './slices/settingSlice'
import user from './slices/userSlice'
import Department from './slices/DepartmentSlice'
import status from './slices/StatusSlice'
import priorty from './slices/PrioritySlice'
const rootReducer = (asyncReducers) => (state, action) => {
    const combinedReducer = combineReducers({
        theme,
        auth,
        section,
        plant,
        alarm,
        task,
        log,
        meter,
        syncmate,
        dashboard,
        graph,
        setting,
        user,
        Department,
        status,
        priorty,
        ...asyncReducers,
    })
    return combinedReducer(state, action)
}
  
export default rootReducer

import React from "react";
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from "configs/AppConfig";

export const publicRoutes = [
  {
    key: "login",
    path: `${AUTH_PREFIX_PATH}/login`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/login")
    ),
  },
  {
    key: "register",
    path: `${AUTH_PREFIX_PATH}/register`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/register")
    ),
  },
  {
    key: "forgot-password",
    path: `${AUTH_PREFIX_PATH}/forgot-password`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/forgot-password")
    ),
  },
];

export const protectedRoutes = [
  {
    key: 'change-password',
    path: `${APP_PREFIX_PATH}/dashboards/change-password`,
    component: React.lazy(() => import('views/app-views/dashboards/changepassword/changepassword')),
},
  {
    key: "dashboard.default",
    path: `${APP_PREFIX_PATH}/dashboards/default`,
    component: React.lazy(() => import("views/app-views/dashboards/default")),
  },
  // {
  //   key: "dashboard.logdetails",
  //   path: `${APP_PREFIX_PATH}/dashboards/logdetails`,
  //   component: React.lazy(() => import("views/app-views/dashboards/logdetails")),
  // },
  {
    key: 'dashboard.section',
    path: `${APP_PREFIX_PATH}/dashboards/section/:plantid`,
    component: React.lazy(() => import('views/app-views/dashboards/section')),
},
{
    key: 'dashboard.sectionadd',
    path: `${APP_PREFIX_PATH}/dashboards/section-add/:plantid`,
    component: React.lazy(() => import('views/app-views/dashboards/section/sectionadd')),
},
{
    key: 'dashboard.sectionedit',
    path: `${APP_PREFIX_PATH}/dashboards/section-edit/:plantid/:id`,
    component: React.lazy(() => import('views/app-views/dashboards/section/sectionedit')),
},
{
    key: 'dashboard.subsection',
    path: `${APP_PREFIX_PATH}/dashboards/subsection/:plantid/:id`,
    component: React.lazy(() => import('views/app-views/dashboards/section/subsection')),
},
  {
    key: 'dashboard.plant',
    path: `${APP_PREFIX_PATH}/dashboards/plant`,
    component: React.lazy(() => import('views/app-views/dashboards/plant')),
},
{
    key: 'dashboard.client.plantadd',
    path: `${APP_PREFIX_PATH}/dashboards/plant-add`,
    component: React.lazy(() => import('views/app-views/dashboards/plant/plantadd')),
},
{
    key: 'dashboard.client.plantedit',
    path: `${APP_PREFIX_PATH}/dashboards/plant-edit/:id`,
    component: React.lazy(() => import('views/app-views/dashboards/plant/plantedit')),
},
  {
    key: "dashboard.section",
    path: `${APP_PREFIX_PATH}/dashboards/section`,
    component: React.lazy(() => import("views/app-views/dashboards/section")),
  },
  {
    key: "dashboard.syncmate",
    path: `${APP_PREFIX_PATH}/dashboards/syncmate`,
    component: React.lazy(() => import("views/app-views/dashboards/syncmate")),
  },
  {
    key: "dashboard.meter",
    path: `${APP_PREFIX_PATH}/dashboards/meter`,
    component: React.lazy(() => import("views/app-views/dashboards/meter")),
  },
  {
    key: 'dashboard.meter.view',
    path: `${APP_PREFIX_PATH}/dashboards/meter/:meter_id`,
    component: React.lazy(() => import('views/app-views/dashboards/meter/view')),
},
  {
    key: "dashboard.assignmeter",
    path: `${APP_PREFIX_PATH}/dashboards/assignmeter`,
    component: React.lazy(() => import("views/app-views/dashboards/assignmeter")),
  },
  {
    key: "dashboards.datatable.all",
    path: `${APP_PREFIX_PATH}/dashboards/datatable/all`,
    component: React.lazy(() => import("views/app-views/dashboards/datatable/all")),
  },
  {
    key: "dashboards.datatable.volatgedatatable",
    path: `${APP_PREFIX_PATH}/dashboards/datatable/volatgedatatable`,
    component: React.lazy(() => import("views/app-views/dashboards/datatable/volatgedatatable")),
  },
  {
    key: "dashboards.datatable.currentdatatable",
    path: `${APP_PREFIX_PATH}/dashboards/datatable/currentdatatable`,
    component: React.lazy(() => import("views/app-views/dashboards/datatable/currentdatatable")),
  },
  {
    key: "dashboard.datatable.powerdatatable",
    path: `${APP_PREFIX_PATH}/dashboards/datatable/powerdatatable`,
    component: React.lazy(() => import("views/app-views/dashboards/datatable/powerdatatable")),
  },
  {
    key: "dashboard.datatable.powerfactordatatable",
    path: `${APP_PREFIX_PATH}/dashboards/datatable/powerfactordatatable`,
    component: React.lazy(() => import("views/app-views/dashboards/datatable/powerfactordatatable")),
  },
  {
    key: "dashboard.datatable.energydatatable",
    path: `${APP_PREFIX_PATH}/dashboards/datatable/energydatatable`,
    component: React.lazy(() => import("views/app-views/dashboards/datatable/energydatatable")),
  },
  {
    key: "dashboard.analycis",
    path: `${APP_PREFIX_PATH}/dashboards/analycis`,
    component: React.lazy(() => import("views/app-views/dashboards/analycis")),
  },
  {
    key: "dashboard.report",
    path: `${APP_PREFIX_PATH}/dashboards/report`,
    component: React.lazy(() => import("views/app-views/dashboards/report")),
  },
  {
    key: "dashboard.alarams",
    path: `${APP_PREFIX_PATH}/dashboards/alarams`,
    component: React.lazy(() => import("views/app-views/dashboards/alarams")),
  },
  {
    key: "dashboard.tasks",
    path: `${APP_PREFIX_PATH}/dashboards/tasks`,
    component: React.lazy(() => import("views/app-views/dashboards/tasks")),
  },
  {
    key: 'dashboard.tasks.view',
    path: `${APP_PREFIX_PATH}/dashboards/tasks/:task_id`,
    component: React.lazy(() => import('views/app-views/dashboards/tasks/view')),
},
  {
    key: "dashboard.logdetails",
    path: `${APP_PREFIX_PATH}/dashboards/logdetails`,
    component: React.lazy(() => import("views/app-views/dashboards/logdetails")),
  },
  {
    key: "dashboard.setting.importfile",
    path: `${APP_PREFIX_PATH}/dashboards/setting/importfile`,
    component: React.lazy(() => import("views/app-views/dashboards/setting/importfile")),
  },
  {
    key: "dashboard.setting.permission",
    path: `${APP_PREFIX_PATH}/dashboards/setting/permission`,
    component: React.lazy(() => import("views/app-views/dashboards/setting/permission")),
  },
  {
    key: "dashboard.setting.Role",
    path: `${APP_PREFIX_PATH}/dashboards/setting/role`,
    component: React.lazy(() => import("views/app-views/dashboards/setting/role")),
  },  {
    key: "dashboard.setting.role/permission",
    path: `${APP_PREFIX_PATH}/dashboards/setting/role/permission/:role_id`,
    component: React.lazy(() => import("views/app-views/dashboards/setting/role/permission")),
  }, {
    key: "dashboard.setting.user",
    path: `${APP_PREFIX_PATH}/dashboards/setting/user`,
    component: React.lazy(() => import("views/app-views/dashboards/setting/user")),
  }, 
  {
    key: "dashboard.setting.employee",
    path: `${APP_PREFIX_PATH}/dashboards/setting/user/employee`,
    component: React.lazy(() => import("views/app-views/dashboards/setting/user/employee")),
  },
  {
    key: "dashboard.master.priority",
    path: `${APP_PREFIX_PATH}/dashboards/priorty`,
    component: React.lazy(() => import("views/app-views/dashboards/master/priorty")),
  },
  {
    key: "dashboard.master.status",
    path: `${APP_PREFIX_PATH}/dashboards/status`,
    component: React.lazy(() => import("views/app-views/dashboards/master/status")),
  },
  {
    key: "dashboard.master.department",
    path: `${APP_PREFIX_PATH}/dashboards/department`,
    component: React.lazy(() => import("views/app-views/dashboards/master/department")),
  },
];

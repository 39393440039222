import fetch from "auth/FetchMasterInterceptor";
import axios from 'axios';
import { API_BASE_URL } from 'configs/AppConfig';
import { AUTH_TOKEN } from 'constants/AuthConstant';

const SettingService = {}
const jwtToken = localStorage.getItem(AUTH_TOKEN) || null;

SettingService.addSetting = function (data) {
	return fetch({
		url: '/setting/add',
		method: 'post',
		data: data
	})
}

SettingService.listSetting = function (data) {
	return fetch({
		url: 'setting/setting-list',
		method: 'post',
		data: data
	})
}

SettingService.getUserConfig = function (data) {
	return fetch({
		url: '/setting/get-setting-info',
		method: 'post',
		data: data
	})
}
SettingService.addUser = function (data) {
	return fetch({
		url: '/user/add',
		method: 'post',
		data: data
	})
}
SettingService.editUser = function (data) {
	return fetch({
		url: '/user/update',
		method: 'post',
		data: data
	})
}
SettingService.deleteUser = function (data) {
	return fetch({
		url: '/user/delete',
		method: 'post',
		data: data
	})
}

SettingService.getRole = function (data) {
	return fetch({
		url: '/role/list',
		method: 'post',
		data: data
	})
}

SettingService.addRole = function (data) {
	return fetch({
		url: '/role/add',
		method: 'post',
		data: data
	})
}
SettingService.editRole = function (data) {
	return fetch({
		url: '/role/update',
		method: 'post',
		data: data
	})
}

SettingService.deleteRole = function (data) {
	return fetch({
		url: '/role/delete',
		method: 'post',
		data: data
	})
}

SettingService.getPermission = function (data) {
	return fetch({
		url: '/permission/list',
		method: 'post',
		data: data
	})
}
SettingService.addPermission = function (data) {
	return fetch({
		url: '/roles/create',
		method: 'post',
		data: data
	})
}
SettingService.editPermission = function (data) {
	return fetch({
		url: '/permission/edit',
		method: 'post',
		data: data
	})
}

SettingService.deletePermission = function (data) {
	return fetch({
		url: '/roles/delete',
		method: 'post',
		data: data
	})
}

SettingService.assignPermission = function (data) {
	return fetch({
		url: '/role/permission/assign',
		method: 'post',
		data: data
	})
}

SettingService.getUser = function (data) {
	return fetch({
		url: '/user/list',
		method: 'post',
		data: data
	})
}

SettingService.uploadimportfile = function (data) {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        "Authorization": `Bearer ${jwtToken}`,
      },
    };
    return axios
      .post(`${API_BASE_URL}/import-excel`, data, config);
  }

  
SettingService.addUser = function (data) {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        "Authorization": `Bearer ${jwtToken}`,
      },
    };
    return axios
      .post(`${API_BASE_URL}/user/add`, data, config);
  }
  
SettingService.uploadimportfile = function (data) {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        "Authorization": `Bearer ${jwtToken}`,
      },
    };
    return axios
      .post(`${API_BASE_URL}/user/update`, data, config);
  }
export default SettingService;